@font-face {
    font-family: Formular;
    src: url("./formular-regular.ttf") format("truetype");
}
ion-button.button-default, ion-button.button-solid{
    --border-radius: 10px !important;
}
sc-map .searchbar-wrapper .searchbar-container{
    border-radius: 10px !important;
}
sc-map .searchbar-wrapper .searchbar-container input{
    border-radius: 10px !important;
}
.in-toolbar.in-segment-color{
    color: white !important;
}
input.searchbar-input{
    border-radius: 10px !important;
}
*{
    font-family: Formular,Arial,Lucida Grande,sans-serif;
}
ion-toolbar ion-button{
    --ionicon-stroke-width: 50px;
}
ion-modal{
    --border-radius: 0.4rem !important;
}
ion-button{
    text-transform: none;
    box-shadow: none;
    --box-shadow:none;
}
